import { formatDate as _formatDate } from 'utils/dateUtil';
import useRouter from './useRouter';

export const useFormatDate = () => {
  const { locale } = useRouter();
  const formatDate = (date?: string | Date, options?: Intl.DateTimeFormatOptions & { locale?: string }) =>
    _formatDate({ date, options: { locale, ...options } });

  return { formatDate };
};
