import SearchFilters from 'components/Algolia/Widgets/SearchFilters/SearchFilters';
import { useRouter } from 'hooks/useRouter';
import { pathnames } from 'i18n/pathnames';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { FacetSort } from 'types/Facet';
import { Text } from 'ui/Text/Text';

const PlpResultsFilter = dynamic(() => import('./Filter/PlpResultsFilter'));

interface PlpResultsHeaderProps {
  hasResults?: boolean;
  sortOptions?: FacetSort[];
  totalResults?: number;
}

const PlpResultsHeader = ({ hasResults, sortOptions, totalResults }: PlpResultsHeaderProps) => {
  const { formatMessage } = useIntl();
  const { pathname } = useRouter();
  const isAlgoliaSearchPage = pathname === pathnames.SEARCH;

  if (!hasResults) return null;

  return (
    <div className="mb-8 hidden h-10 w-full items-center justify-between border-b border-accent-40 lg:flex">
      <Text type="small">
        {`${totalResults} ${formatMessage({
          id: 'search_result_results',
        })}`}
      </Text>
      {isAlgoliaSearchPage ? (
        <SearchFilters />
      ) : sortOptions?.length ? (
        <PlpResultsFilter sortFacetOptions={sortOptions} />
      ) : null}
    </div>
  );
};

export default PlpResultsHeader;
