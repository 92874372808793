import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface FacetState {
  mobileFacetsVisible: boolean;
}

export interface FacetStore extends FacetState {
  actions: {
    setMobileFacetsVisible: (visible: boolean) => void;
  };
}

const initialState: FacetState = {
  mobileFacetsVisible: false,
};

export const useFacetStore = create<FacetStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        setMobileFacetsVisible: (visible: boolean) => set(() => ({ mobileFacetsVisible: visible })),
      },
    }),
    { name: 'facetStore' },
  ),
);

export const useMobileFacetsVisible = () => useFacetStore((state) => state.mobileFacetsVisible);
export const useFacetStoreActions = () => useFacetStore((state) => state.actions);
