import { useProducts } from 'features/product/queries';
import { useCriteoTracking } from 'hooks/useCriteoTracking';
import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import { FlagshipPlacement } from 'types/Criteo';
import { Product } from 'types/Product';
import { log } from 'utils/loggerUtil';

export const useFlagship = (placements: FlagshipPlacement[]) => {
  const { asPath } = useRouter();
  const { pushCriteoBeacon } = useCriteoTracking();

  const flagshipProductIds = placements.flatMap((placement) => placement.items.map((product) => product.id)) ?? [];
  const { data: fetchedProducts = [], isLoading } = useProducts({ productCodes: flagshipProductIds });

  const mappedPlacements = placements.map((placement) => {
    const items = placement.items.reduce((acc: Product[], item) => {
      const product = fetchedProducts.find((fetchedProduct) => fetchedProduct.code === item.id);
      if (!product) return acc;

      const mappedProduct: Product = {
        ...product,
        isSponsored: true,
        tracking: {
          OnClickBeacon: item.onClickUrl,
          OnLoadBeacon: item.onLoadUrl,
          OnViewBeacon: item.onViewUrl,
        },
      };

      return [...acc, mappedProduct];
    }, []);

    return {
      ...placement,
      items,
    };
  });

  useEffect(() => {
    if (!pushCriteoBeacon) return;
    placements.forEach((placement) => {
      if (placement.onLoadUrl) {
        log(`Criteo - FS - ${asPath}`, 'pushing OnLoadBeacon for format', placement.onLoadUrl);
        pushCriteoBeacon(placement.onLoadUrl);
      }

      placement.items.forEach((product) => {
        if (product.onLoadUrl) {
          log(`Criteo - FS - ${asPath}`, `pushing OnLoadBeacon for FS product with code: ${product?.id}`);
          pushCriteoBeacon(product.onLoadUrl);
        }
      });
    });
  }, []);

  const onViewFlagship = (placement: FlagshipPlacement) => {
    if (!pushCriteoBeacon) return;
    if (placement.onViewUrl) {
      log(`Criteo - FS - ${asPath}`, 'pushing OnViewBeacon for FS format', placement.onViewUrl);
      pushCriteoBeacon(placement.onViewUrl);
    }
  };

  const onViewFlagshipProduct = (product: Product) => {
    if (!pushCriteoBeacon) return;
    if (product.tracking?.OnViewBeacon) {
      log(`Criteo - FS - ${asPath}`, `pushing OnViewBeacon for FS product with code: ${product.code}`);
      pushCriteoBeacon(product.tracking.OnViewBeacon);
    }
  };

  const onClickFlagship = (placement: FlagshipPlacement) => {
    if (!pushCriteoBeacon) return;
    if (placement.onClickUrl) {
      log(`Criteo - FS - ${asPath}`, 'pushing OnClickBeacon for FS format', placement.onClickUrl);
      pushCriteoBeacon(placement.onClickUrl);
    }
  };

  const onClickFlagshipProduct = (product: Product) => {
    if (!pushCriteoBeacon) return;
    if (product.tracking?.OnClickBeacon) {
      log(`Criteo - FS - ${asPath}`, `pushing OnClickBeacon for FS product with code: ${product.code}`);
      pushCriteoBeacon(product.tracking.OnClickBeacon);
    }
  };

  return {
    isLoading,
    mappedPlacements,
    onClickFlagship,
    onClickFlagshipProduct,
    onViewFlagship,
    onViewFlagshipProduct,
  };
};
