import styled, { css } from 'styled-components';
import { breakpointUp, spacer, color, variable, specific } from 'theme';
import { BREAKPOINTS } from '../../constants/breakpoints';
import Flex from '../Flex/Flex';

export const StyledPagination = styled(Flex)`
  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    `
  )}
`;

export const StyledPaginationLinks = styled(Flex)`
  .pagination-block {
    color: ${color('whisper-400')};
    background-color: ${color('whisper-100')};
    width: ${spacer(200)};
    height: ${spacer(200)};
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    transition: ${variable('transition')};

    &.active,
    &:hover {
      background-color: ${specific('facet', 'active-color')};
      color: ${color('white')};
    }
  }

  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      gap: ${spacer(200)};
    `
  )}
`;
