import { FlexItem, Spacer } from 'constants/styling';
import styled, { css } from 'styled-components';
import { spacer, color as clr } from 'theme';

export interface StyledPriceWrapperProps {
  alignPriceItems?: FlexItem;
  marginBottom?: Spacer;
  marginTop?: Spacer;
  reversePricePositions?: boolean;
  sameLine?: boolean;
}

export const StyledPriceWrapper = styled.div<StyledPriceWrapperProps>`
  display: flex;
  gap: ${spacer(50)};
  align-items: ${({ alignPriceItems }) => alignPriceItems && alignPriceItems};
  margin-top: ${({ marginTop }) => spacer(marginTop)};
  margin-bottom: ${({ marginBottom }) => spacer(marginBottom)};

  ${({ reversePricePositions }) =>
    reversePricePositions
      ? css<StyledPriceWrapperProps>`
          flex-direction: ${({ sameLine }) => (sameLine === true ? 'row-reverse' : 'column-reverse')};
        `
      : css<StyledPriceWrapperProps>`
          flex-direction: ${({ sameLine }) => (sameLine === true ? 'row' : 'column')};
        `};

  &:empty {
    display: none;
  }
`;

export const StyledPrice = styled.span`
  line-height: 100%;
  white-space: nowrap;

  color: ${({ color }) => clr(color)};

  &:hover {
    color: ${({ color }) => clr(color)};
  }
`;
