import { ShowArrowsObject, SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { ReactNode, RefObject, useRef } from 'react';
import { StyledSlider } from './SliderCenteredArrowsWithMargin.styled';

interface SliderButtonLabelsProps {
  children: ReactNode;
  className?: string;
  id?: string;
  options?: EmblaOptionsType;
  showArrows?: ShowArrowsObject;
  sliderRef?: RefObject<EmblaCarouselType>;
  slidesToShow?: SlidesObject;
}

const SliderCenteredArrowsWithMargin = ({
  children,
  className,
  id,
  options: optionsProp,
  showArrows = { [BREAKPOINTS.MD]: true },
  sliderRef: sliderRefProp,
  slidesToShow = { [BREAKPOINTS.XS]: 'auto' },
}: SliderButtonLabelsProps) => {
  const sliderRef = useRef<EmblaCarouselType>(null);

  const ref = sliderRefProp || sliderRef;

  const currentSliderHasArrows = !(!ref?.current?.canScrollPrev() && !ref?.current?.canScrollNext());

  const options: EmblaOptionsType = {
    align: 'start',
    ...optionsProp,
  };

  return (
    <StyledSlider
      arrowPosition={ARROW_POSITIONS.CENTER}
      slidesToShow={slidesToShow}
      ref={ref}
      showArrows={showArrows}
      hasArrows={currentSliderHasArrows}
      className={className}
      options={options}
      id={id}
    >
      {children}
    </StyledSlider>
  );
};

export default SliderCenteredArrowsWithMargin;
