import { useIntl } from 'react-intl';
import Flex from '../Flex/Flex';
import Typography from '../Typography/Typography';
import { StyledPagination, StyledPaginationLinks } from './Pagination.styled';

interface PaginationProps {
  amountOfResults: number;
  className?: string;
  createPageLink: (page: number, label: string, className?: string) => JSX.Element;
  currentPage?: number;
  pageSize?: number;
  totalPages?: number;
  zeroIndex?: boolean;
}

const Pagination = ({
  amountOfResults,
  className,
  createPageLink,
  currentPage = 0,
  pageSize = 24,
  totalPages = 0,
  zeroIndex = true,
}: PaginationProps) => {
  const { formatMessage } = useIntl();

  const currentPageNumber = currentPage + (zeroIndex ? 1 : 0) || 1;
  const totalPagesNumber = totalPages || 0;

  const linksBefore = [];
  const linksAfter = [];

  for (let index = currentPageNumber - 1; index > 0; index -= 1) {
    if (linksBefore.length < 2) {
      linksBefore.unshift(index);
    }
  }

  for (let index = currentPageNumber + 1; index <= totalPagesNumber; index += 1) {
    if (linksAfter.length < 2) {
      linksAfter.push(index);
    }
  }

  const resultsString = `${pageSize || 0} ${formatMessage({
    id: 'search_result_results',
  })}`;

  const inTotalString = `(${formatMessage(
    {
      id: 'amount_in_total',
    },
    { value: amountOfResults },
  )})`;

  const paginationString = `${resultsString} ${inTotalString}`;

  if (totalPages <= 1) {
    return null;
  }

  return (
    <StyledPagination
      alignItems="center"
      className={`${className} pagination`}
      flexDirection="column"
      gap={200}
      justifyContent="center"
    >
      <Typography fontWeight="normal" marginBottom={0} type="h3">
        {paginationString}
      </Typography>

      <StyledPaginationLinks alignItems="center" className="pagination-links" gap={100}>
        {linksBefore.length === 2 && linksBefore[0] !== 1 && createPageLink(zeroIndex ? 0 : 1, '1', 'pagination-block')}

        <Flex alignItems="center" className="pagination-numbers" gap={25}>
          {linksBefore.map((link) => (
            <span key={link}>{createPageLink(link - 1, link.toString(), 'pagination-block')}</span>
          ))}
          <div className="pagination-block active">{currentPageNumber}</div>
          {linksAfter.map((link) => (
            <span key={link}>{createPageLink(link - (zeroIndex ? 1 : 0), link.toString(), 'pagination-block')}</span>
          ))}
        </Flex>
        {linksAfter.length === 2 &&
          linksAfter[1] !== totalPages &&
          createPageLink(totalPagesNumber - (zeroIndex ? 1 : 0), totalPagesNumber.toString(), 'pagination-block')}
      </StyledPaginationLinks>
    </StyledPagination>
  );
};

export default Pagination;
