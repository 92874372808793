import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';

interface StyledSliderProps {
  hasArrows?: boolean;
}

export const StyledSlider = styled(SliderEmbla)<StyledSliderProps>`
  & {
    .embla {
      .embla__container {
        gap: ${spacer(25)};
      }

      .embla__viewport {
        ${breakpointUp(
          BREAKPOINTS.MD,
          css<StyledSliderProps>`
            margin-left: ${({ hasArrows }) => hasArrows && spacer(325)};
            margin-right: ${({ hasArrows }) => hasArrows && spacer(325)};
          `
        )}
      }

      .embla__button__prev {
        left: 0px;
      }

      .embla__button__next {
        right: 0px;
      }
    }
  }
`;

export const StyledLabel = styled.div`
  border-radius: ${spacer(250)};
  background-color: ${color('whisper-100')};
  padding: ${spacer(75)} ${spacer(150)};
  color: ${color('black')};

  &:active {
    color: ${color('primary-300')};
  }

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      &:hover {
        background-color: ${color('primary-300')};
        color: ${color('white')};
        font-weight: bold;
      }
    `
  )}
`;
