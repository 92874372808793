import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'ui/Select/Select';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';

export type SortOption = {
  label: string;
  value: string;
};

type PlpResultsFilterDropdownProps = {
  className?: string;
  handleSort: (value: string) => void;
  selectedSortOption?: SortOption;
  sortOptions: SortOption[];
};

const PlpResultsFilterDropdown = ({
  className,
  handleSort,
  selectedSortOption,
  sortOptions,
}: PlpResultsFilterDropdownProps) => {
  const { label: selectedSortLabel, value: selectedSortValue } = selectedSortOption ?? {};

  return (
    <Select value={selectedSortValue} onValueChange={handleSort}>
      {/* TODO: Possibly remove context-specific styling; Do we want this component to have a p-2 by default? The SelectedTrigger also has h-10 by default which might not be desirable. Should we remove these? */}
      <SelectTrigger className={cn('border-none p-2 pl-0 font-bold', className)}>
        <SelectValue placeholder={selectedSortLabel} />
      </SelectTrigger>
      <SelectContent className="bg-white">
        <SelectGroup>
          {sortOptions?.map(({ label, value }) => (
            <SelectItem key={value} value={value}>
              <Text type="small">{label}</Text>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
export default PlpResultsFilterDropdown;
