import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaOptionsType } from 'embla-carousel';
import { useElementViewed } from 'hooks/useElementViewed';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { CategoryBanner } from 'types/Category';
import { Image } from 'ui/Image/Image';
import { OptionalLink } from 'ui/Link/Link';

const useBannerTracking = (banner: CategoryBanner) => {
  const { asPath: path, locale } = useRouter();
  const { onComponentClick, onComponentView } = useTrackingEvents();
  const { uid } = banner;
  const component = 'Banner';
  const campaign = [component, uid, locale].filter(Boolean).join('-');

  const { ref } = useElementViewed(() => {
    onComponentView({
      campaign,
      component,
      path,
    });
  });

  const onClick = () => {
    onComponentClick({
      campaign,
      component,
      path,
    });
  };

  return { onClick, ref };
};

type PlpBannerProps = {
  banner: CategoryBanner;
  priority: boolean;
};

const PlpBanner = ({ banner, priority }: PlpBannerProps) => {
  const { onClick, ref } = useBannerTracking(banner);
  return (
    <OptionalLink
      ref={ref}
      onClick={onClick}
      newTab={banner?.openInNewTab ?? true} // Defaulting to true when undefined to the previous behavior
      href={banner.url}
      className="focus-border relative block aspect-banner-plp-mobile w-full overflow-hidden rounded-lg md:aspect-banner-plp-desktop"
    >
      {/* Mobile */}
      <Image
        alt="banner-image"
        quality={100}
        src={banner?.bannerMobile?.url ?? ''}
        useNext={true}
        className="md:hidden"
        priority={priority}
        fill
        nextSizes={{
          default: 375,
        }}
        style={{
          objectFit: 'contain',
        }}
      />

      {/* Desktop */}
      <Image
        alt="banner-image"
        quality={100}
        useNext={true}
        fill
        nextSizes={{ default: 1000 }}
        src={banner?.bannerWide?.url ?? ''}
        className="hidden md:block"
        priority={priority}
        style={{
          objectFit: 'contain',
        }}
      />
    </OptionalLink>
  );
};

type PlpBannerCarouselProps = {
  categoryBanners?: CategoryBanner[];
  className?: string;
};

const PlpBannerCarousel = ({ categoryBanners = [], className }: PlpBannerCarouselProps) => {
  const filteredCategoryBanners = categoryBanners.filter((banner) => banner?.url && banner?.bannerWide?.url);

  if (!filteredCategoryBanners?.length) {
    return null;
  }

  const options: EmblaOptionsType = {
    loop: true,
  };

  if (filteredCategoryBanners.length === 1) {
    return <PlpBanner banner={filteredCategoryBanners[0]} priority />;
  }

  return (
    <SliderEmbla
      options={options}
      slidesToShow={{
        [BREAKPOINTS.XS]: 1,
      }}
      arrowPosition={ARROW_POSITIONS.CENTER}
      showArrows={{ [BREAKPOINTS.MD]: true }}
      showArrowsOnHover={true}
      className={className}
      showProgressBar={true}
    >
      {filteredCategoryBanners?.map((banner, index) => (
        <PlpBanner key={index} banner={banner} priority={index === 0} />
      ))}
    </SliderEmbla>
  );
};

export default PlpBannerCarousel;
