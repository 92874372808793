import usePrice from 'hooks/usePrice';
import { useIntl } from 'react-intl';
import { Color } from 'types/Color';
import { FontSize, FontWeight } from '../../constants/styling';
import Typography from '../Typography/Typography';
import { StyledPrice, StyledPriceWrapper, StyledPriceWrapperProps } from './Price.styled';

// TODO: simplify dom elements and take out numberformatting as this is done in the backend

interface PriceProps extends StyledPriceWrapperProps {
  className?: string;
  color?: Color;
  currentPrice: number;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  isReversedPricePositions?: boolean;
  oldPrice?: number;
  oldPriceFontSize?: FontSize;
  onClick?: () => void;
  showFreePriceText?: boolean;
  strikeColor?: Color;
}

/**
 *
 * @deprecated
 * Use the PriceNew component instead
 */
const Price = ({
  alignPriceItems,
  className,
  color = 'black',
  currentPrice,
  fontSize = 150,
  fontWeight = 'bold',
  isReversedPricePositions,
  marginBottom,
  marginTop,
  oldPrice,
  oldPriceFontSize = 100,
  onClick,
  sameLine,
  showFreePriceText = true,
  strikeColor = 'whisper-300',
}: PriceProps) => {
  const { formatMessage } = useIntl();
  const { formatPrice } = usePrice();

  const freePriceValue = showFreePriceText
    ? formatMessage({ id: 'cart_free_product_text' })
    : formatPrice(currentPrice);

  const priceValue = (
    <Typography className="current-price" color={color} fontSize={fontSize} fontWeight={fontWeight}>
      {Number(currentPrice) === 0 ? freePriceValue : formatPrice(currentPrice)}
    </Typography>
  );

  return (
    <StyledPriceWrapper
      className={`${className || ''} price-wrapper`}
      marginBottom={marginBottom}
      marginTop={marginTop}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      sameLine={sameLine}
      tabIndex={0}
      alignPriceItems={alignPriceItems}
      reversePricePositions={isReversedPricePositions}
    >
      {!!oldPrice && Number(oldPrice) > Number(currentPrice) && (
        <StyledPrice color={strikeColor}>
          <Typography
            className="original-price"
            color={strikeColor}
            fontSize={oldPriceFontSize}
            fontWeight="normal"
            textDecoration="line-through"
          >
            {formatPrice(oldPrice)}
          </Typography>
        </StyledPrice>
      )}

      {Number(currentPrice) >= 0 && <StyledPrice color={color}>{priceValue}</StyledPrice>}
    </StyledPriceWrapper>
  );
};

export default Price;
