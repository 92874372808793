import SliderCenteredArrowsWithMargin from 'components/SliderCenteredArrowsWithMargin/SliderCenteredArrowsWithMargin';
import { IS_KREFEL } from 'constants/brand';
import { Position } from 'constants/styling';
import { useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFacetStoreActions } from 'store/facets';
import { Brand } from 'types/Brand';
import { CMSSearchPage } from 'types/CMS';
import { Category } from 'types/Category';
import { PcConfigurationPart } from 'types/PcConfiguration';
import { Button } from 'ui/Button/Button';
import { Icon } from 'ui/Icon/Icon';
import { Text } from 'ui/Text/Text';
import { useEventListener } from 'usehooks-ts';
import { cn } from 'utils/cn';
import PlpBannerCarousel from './PlpBanner';

const FILTERS_BUTTON_ID = 'filterButton';

interface PlpHeaderProps {
  brand?: Brand;
  category?: Category;
  className?: string;
  cmsSearchPage?: CMSSearchPage;
  pcConfigurationPart?: PcConfigurationPart;
  searchQuery?: string;
}
const PlpHeader = ({ brand, category, className, cmsSearchPage, pcConfigurationPart, searchQuery }: PlpHeaderProps) => {
  const { formatMessage } = useIntl();

  const {
    buttons,
    description,
    fallbackText,
    plpContentBanners: categoryBanners,
    title,
  } = category || cmsSearchPage || {};

  const { name } = category || {};
  const { name: brandName } = brand || {};
  const { name: pcPartName } = pcConfigurationPart || {};

  const algoliaSearchPageTitle = searchQuery ? `${formatMessage({ id: 'search_result_query' })} ${searchQuery}` : null;
  const brandPageTitle = brandName
    ? formatMessage({ id: 'brand_all_products_page_title' }, { brand: brandName })
    : null;

  const headerTitle = algoliaSearchPageTitle || title || name || pcPartName || brandPageTitle;

  const headerTopPosition = useRef<number>(0);
  const descriptionRef = useRef<HTMLSpanElement>(null);
  const descriptionReadMoreButtonRef = useRef<HTMLButtonElement>(null);

  const [truncateDescription, setTruncateDescription] = useState(true);
  const [filtersButtonPosition, setFiltersButtonPosition] = useState<Position>('initial');

  const { setMobileFacetsVisible } = useFacetStoreActions();

  const handleFiltersButton = () => {
    setMobileFacetsVisible(true);
  };

  const [isReadMoreButtonHidden, setIsReadMoreButtonHidden] = useState(true);

  const handleResize = () => {
    const scrollHeight = descriptionRef?.current?.scrollHeight || 0;
    const clientHeight = descriptionRef?.current?.clientHeight || 0;

    // The text has the possibility to be truncated
    if (scrollHeight > clientHeight) {
      setIsReadMoreButtonHidden(false);
    }

    if (scrollHeight <= clientHeight) {
      setIsReadMoreButtonHidden(true);
      setTruncateDescription(true);
    }
  };

  useEventListener('scroll', () => {
    const navigation = document.getElementById(FILTERS_BUTTON_ID);
    const elementPosition = navigation?.getBoundingClientRect().top || 0;

    if (window.scrollY > elementPosition) {
      setFiltersButtonPosition('fixed');
    } else {
      setFiltersButtonPosition('initial');
    }
  });

  useLayoutEffect(() => {
    const headerElement = document.getElementById('header');
    if (headerElement) {
      headerTopPosition.current = headerElement.getBoundingClientRect()?.height - 1 ?? 0;
    }

    handleResize();
  }, []);

  useEventListener(
    'focusin',
    () => {
      setTruncateDescription(false);
      if (descriptionRef.current) {
        descriptionRef.current.scrollTop = 0; // Prevents the description from being scrolled down before collapsed again
      }
    },
    descriptionRef,
  );
  useEventListener('resize', () => handleResize());

  const categoryButtons = !!buttons?.length && (
    <SliderCenteredArrowsWithMargin id={FILTERS_BUTTON_ID} options={{ dragFree: true }} className="overflow-hidden">
      {buttons.map((button) => {
        if (!(button?.text && button?.url)) {
          return null;
        }

        // FIXME: This is a temporary fix for the filter buttons. After adjusting the Button UI component with the pill variant, we can adjust this component.
        return (
          <Button
            key={button.url}
            variant="ghost"
            className={cn(
              'border px-6 py-2 font-normal',
              IS_KREFEL
                ? 'border-primary-30/10 bg-primary-30/10 hover:border-primary-30 hover:text-black'
                : 'border-accent-20 bg-accent-20',
            )}
            href={button.url}
          >
            {button.text}
          </Button>
        );
      })}
    </SliderCenteredArrowsWithMargin>
  );

  return (
    <div className={cn('flex flex-col gap-3', className)}>
      <PlpBannerCarousel categoryBanners={categoryBanners} />

      {headerTitle && (
        <Text tag="h1" type="h2" className="m-0">
          {headerTitle}
        </Text>
      )}

      {description && (
        <div>
          <Text
            ref={descriptionRef}
            html={description}
            className={cn(
              '[&>p]:m-0',
              truncateDescription ? 'line-clamp-2 max-h-12 overflow-hidden md:line-clamp-3 md:max-h-[4.5rem]' : '',
            )}
          />

          <Button
            ref={descriptionReadMoreButtonRef}
            onClick={() => setTruncateDescription((truncate) => !truncate)}
            className={cn('p-0 font-normal underline', isReadMoreButtonHidden ? 'hidden' : '')}
            variant="ghost"
          >
            {formatMessage({ id: truncateDescription ? 'general_read_more_button' : 'general_read_less_button' })}
          </Button>
        </div>
      )}

      {fallbackText && (
        <Text html={fallbackText} className={'rounded-md border-2 border-primary-30 bg-accent-20 px-4 py-3'} />
      )}

      {/* Mobile wrapper */}
      <div
        style={filtersButtonPosition === 'fixed' ? { top: `${headerTopPosition.current}px` } : {}}
        className={cn(
          'flex lg:hidden',
          filtersButtonPosition === 'fixed' ? `fixed left-0 z-header-overwrite w-full bg-white px-2 py-3` : '',
        )}
      >
        <div className={cn('flex items-center gap-1 lg:hidden')}>
          {/* TODO: This button overrides the existing button styling. Check for any redesign to  use components within the design system. */}
          <Button
            variant="ghost"
            className={cn(
              'h-fit gap-3 border border-primary-30 bg-primary-30 bg-opacity-10 px-6 py-2 font-normal lg:hidden',
              !buttons?.length ? 'w-full' : '',
            )}
            onClick={handleFiltersButton}
            icon={{ name: 'sliders-simple', styling: 'fal' }}
          >
            {formatMessage({
              id: 'category_filters_button_title',
            })}
          </Button>

          {!!buttons?.length && <Icon icon={{ name: 'pipe', styling: 'fat' }} className="h-10 text-accent-20" />}
        </div>

        {categoryButtons}
      </div>

      {/* Desktop */}
      {!!categoryButtons && <div className="hidden lg:block">{categoryButtons}</div>}
    </div>
  );
};

export default PlpHeader;
