import PlpResultsFilterDropdown from 'components/Layout/Plp/Results/Filter/PlpResultsFilterDropdown';
import { useSortBy } from 'react-instantsearch-core';
import { useIntl } from 'react-intl';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { useSortValues } from '../../../../features/search/queries';

interface SearchFiltersProps {
  className?: string;
}

const SearchFilters = ({ className }: SearchFiltersProps) => {
  const { formatMessage } = useIntl();
  const { data: sorts } = useSortValues();

  const algoliaSortOptions =
    sorts?.options.map((sort) => ({
      label: sort.displayName,
      value: sort.code,
    })) || [];

  const { currentRefinement, options: sortOptions, refine } = useSortBy({ items: algoliaSortOptions });

  const selectedSortOption = sortOptions.find((option) => option.value === currentRefinement);

  if (!sorts?.options?.length) return null;

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Text type="small" className="block whitespace-nowrap">
        {formatMessage({ id: 'search_sorting' })}:
      </Text>
      <PlpResultsFilterDropdown handleSort={refine} selectedSortOption={selectedSortOption} sortOptions={sortOptions} />
    </div>
  );
};

export default SearchFilters;
